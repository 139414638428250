<template>
  <div>
    <div
      v-if="sourceUrl"
      v-dragscroll="true"
      :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]"
    >
      <pdf style="display: none;" :src="base64pdf" @num-pages="pageCount = new Array($event)" />
      <!-- Actual document -->
      <pdf
        v-for="(page, index) in pageCount"
        doc-viewer-pdf
        :key="index"
        :src="sourceUrl"
        :page="index + 1"
        :scale="scale"
        :resize="true"
        :style="{ margin: 'auto', width: `${100 * scale}%` }"
      />
    </div>

    <div class="doc-controls has-text-center">
      <b-field position="is-centered">
        <div class="control">
          <b-button icon-left="magnify-minus" @click="zoom(-1)"></b-button>
        </div>
        <div class="control">
          <b-button icon-left="magnify-plus" @click="zoom(1)"></b-button>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
/*
  <DocViewer 
    documentId
    containerId
    file
  />
*/

import pdf from 'vue-pdf'
import { dragscroll } from 'vue-dragscroll'
import kms from '@/services/kms'

export default {
  components: {
    pdf
  },

  directives: { dragscroll },

  data: () => {
    return {
      sourceUrl: null,
      scale: 1,
      pageCount: []
    }
  },

  props: ['documentId', 'containerId', 'file'],

  watch: {
    file() {
      this.updateSourceUrl()
    },
    documentId() {
      this.updateSourceUrl()
    },
    containerId() {
      this.updateSourceUrl()
    }
  },

  mounted() {
    this.updateSourceUrl()
  },

  methods: {
    updateSourceUrl() {
      const { documentId, containerId, file } = this

      // If we have document and container IDs, this file has been uploaded
      // load it from the back-end
      if (documentId && containerId) {
        const path = `/Document/GetFile?containerID=${containerId}&documentID=${documentId}`
        this.sourceUrl = pdf.createLoadingTask({
          url: kms.url(path),
          httpHeaders: kms.headers(),
          withCredentials: true
        })

        return
      }

      this.sourceUrl = null

      if (file) {
        if (file.type != 'application/pdf') {
          console.error(file.name, 'is not a pdf file.')
          this.sourceUrl = null
          return
        }

        // Read local file as typed array (PDF.js will handle it)
        const fileReader = new FileReader()
        fileReader.onload = e => {
          this.sourceUrl = new Uint8Array(e.target.result)
        }
        fileReader.readAsArrayBuffer(file)
      }
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}
</style>
