var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ padding: '200px 100px' })},[_c('h1',[_vm._v("DocViewer Example")]),_c('div',{style:({ display: 'flex', width: '100%' })},_vm._l((_vm.documents),function(document,index){return _c('div',{key:index,style:({
        display: 'flex',
        alignItems: 'center',
        width: '600px',
        height: '600px',
        background: '#fff',
        borderRadius: '4px',
        margin: '0 10px'
      })},[_c('DocViewer',{style:({ width: '100%', height: '100%' }),attrs:{"file":document}})],1)}),0),_c('PDFUploader',{attrs:{"update":_vm.documentsUpdated,"multiple":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }