<template>
  <div :style="{ padding: '200px 100px' }">
    <h1>DocViewer Example</h1>

    <div :style="{ display: 'flex', width: '100%' }">
      <div
        v-for="(document, index) in documents"
        :key="index"
        :style="{
          display: 'flex',
          alignItems: 'center',
          width: '600px',
          height: '600px',
          background: '#fff',
          borderRadius: '4px',
          margin: '0 10px'
        }"
      >
        <!-- <PDF
          :src="file"
          :scale="1"
          :resize="true"
          :style="{ margin: 'auto', width: `${100 * 1}%` }"
        /> -->

        <DocViewer :file="document" :style="{ width: '100%', height: '100%' }" />
      </div>
    </div>

    <PDFUploader :update="documentsUpdated" :multiple="false" />
  </div>
</template>

<script>
import PDFUploader from '@/components/Fields/PDFUploader'
import _get from 'lodash/get'
import DocViewer from '../index'

export default {
  components: {
    DocViewer,
    PDFUploader
  },
  data() {
    return {
      files: [],
      documents: []
    }
  },
  methods: {
    documentsUpdated({ documents }) {
      console.log({ documents })
      this.documents = documents

      for (let a = 0; a < documents.length; a++) {
        const doc = documents[a]
        const docType = _get(doc, 'type', '')

        if (docType === 'application/pdf') {
          const fileReader = new FileReader()
          fileReader.onload = e => {
            this.files[a] = new Uint8Array(e.target.result)
            this.$forceUpdate()
          }

          fileReader.readAsArrayBuffer(doc)
        } else {
          this.files[a] = null
        }
      }

      console.log({ files: this.files })
    }
  }
}
</script>
